import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';

export const useStorefrontEmptyStates = (): boolean => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const featureFlags = useFeatureFlags();

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_EMPTY_STATES] && vendor?.isPaid,
	);
};
