import useImage from '@hooks/useImage';
import React, { type FC } from 'react';
import ImageWrapper from './ImageWrapper';
import { PLACEHOLDER_CONSTANTS } from './constants';
import type { CommonImageProps } from './types';

interface PhotoProps extends CommonImageProps {
	height?: number;
	mediaItem: Vendor.Media;
	preload?: boolean;
	width?: number;
	smartCrop?: boolean;
	fit?: boolean;
	lazy?: boolean;
}

const Photo: FC<PhotoProps> = (props) => {
	const [imageStatus, onLoad, onError] = useImage(PLACEHOLDER_CONSTANTS.url);

	return (
		<ImageWrapper
			{...props}
			imageStatus={imageStatus}
			onError={onError}
			onLoad={onLoad}
		/>
	);
};

export default Photo;
