import {
	nextMediaAtom,
	prevMediaAtom,
} from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useAppSelector } from '@redux/hooks';
import type { Decorated } from '@typings/vendor';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

export const useLightboxKeydown = () => {
	const nextImage = useSetAtom(nextMediaAtom);
	const prevImage = useSetAtom(prevMediaAtom);
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const keydown = useCallback(() => {
		return (event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowLeft': {
					prevImage(() =>
						trackUnifiedLightbox(
							AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
							AnalyticsConstants.CLICKED_LEFT,
							AnalyticsConstants.PHOTO_DETAIL_VIEW,
						),
					);
					break;
				}

				case 'ArrowRight': {
					nextImage(() =>
						trackUnifiedLightbox(
							AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
							AnalyticsConstants.CLICKED_RIGHT,
							AnalyticsConstants.PHOTO_DETAIL_VIEW,
						),
					);
					break;
				}
				default:
					break;
			}
		};
	}, [nextImage, prevImage, trackUnifiedLightbox]);

	useEffect(() => {
		const keydownEvent = keydown();
		window.addEventListener('keydown', keydownEvent);
		return () => {
			window.removeEventListener('keydown', keydownEvent);
		};
	}, [keydown]);
};
