import { createSelector } from 'reselect';

export const selectViewport = (state: Redux.State) => state.viewport;

export const selectIsMobile = createSelector(selectViewport, (viewport) => {
	if (viewport.isMobile) {
		return true;
	}

	// This should actually be checking extra small to match union
	if (viewport.isClientSide && !viewport.greaterThan.small) {
		return true;
	}

	return false;
});

export const selectIsMobileApp = createSelector(
	selectViewport,
	(viewport) => !!viewport.isMobileApp,
);

export const selectIsTablet = createSelector(selectViewport, (viewport) => {
	if (viewport.isTablet) {
		return true;
	}

	if (viewport.isClientSide && !viewport.greaterThan.small) {
		return true;
	}

	return false;
});

export const selectIsMobileTablet = createSelector(
	[selectIsMobile, selectIsTablet],
	(mobile, tablet) => mobile || tablet,
);
