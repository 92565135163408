import savedVendors from '@utils/savedVendors';
import { useCallback } from 'react';

export const useDestroyFavorite = (
	sessionToken: string | null,
	weddingId: string | undefined,
	savedVendorId: string | undefined | null,
	setSavedVendorId: (savedVendorId?: string | null) => void,
	emptyFavoriteHeart: () => void,
) => {
	return useCallback(() => {
		return savedVendors
			.destroyByObjectId({
				sessionToken,
				weddingId,
				savedVendorId,
			})
			.then(() => {
				emptyFavoriteHeart();
				setSavedVendorId(null);
			});
	}, [
		sessionToken,
		emptyFavoriteHeart,
		setSavedVendorId,
		weddingId,
		savedVendorId,
	]);
};
