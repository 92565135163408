import PartialStar from '@xo-union/component-partial-star';
import React, { type FC, useMemo } from 'react';

import classNames from 'classnames';
import type { Size } from 'types/union/common';
import { roundReviewRating } from '../../../../../../../constants/roundReviewRating';
import Styles from './styles.scss';

interface Props {
	overallRating: number;
	size?: Size;
	wrapperClass?: string;
}

export const ReviewStars: FC<Props> = ({
	overallRating,
	size = 'sm',
	wrapperClass = '',
}) => {
	const stars = useMemo(() => {
		return [0, 1, 2, 3, 4].map((starIndex, idx) => {
			const key = `star-${idx}`;
			if (Math.floor(overallRating) > starIndex) {
				return <PartialStar fill={1} size={size} key={key} />;
			}
			if (Math.floor(overallRating) === starIndex) {
				return (
					<PartialStar
						fill={roundReviewRating(overallRating % 1)}
						size={size}
						key={key}
					/>
				);
			}
			return <PartialStar fill={0} size={size} key={key} />;
		});
	}, [overallRating, size]);

	return (
		<div className={classNames(Styles.reviewStarsWrapper, wrapperClass)}>
			{stars}
		</div>
	);
};

export default ReviewStars;
