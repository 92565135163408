function debounce<T extends Function>(
	func: T,
	wait: number,
	immediate: boolean,
): () => void {
	let timeout: NodeJS.Timeout | null;
	return function handleDebounce(...args: T[]) {
		clearTimeout(timeout);
		timeout = setTimeout(function setTimeoutCb() {
			timeout = null;
			if (!immediate) func.apply(this, args);
		}, wait);
		if (immediate && !timeout) func.apply(this, args);
	};
}

export default debounce;
