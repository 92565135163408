import CONSTANTS from '../constants';

export function fillFavoriteHeart() {
	return {
		type: CONSTANTS.FILL_FAVORITE_HEART,
	};
}

export function emptyFavoriteHeart() {
	return {
		type: CONSTANTS.EMPTY_FAVORITE_HEART,
	};
}

export function setWeddingId(weddingId?: string) {
	return {
		type: CONSTANTS.SET_WEDDING_ID,
		payload: weddingId,
	};
}

export function setSavedVendorId(savedVendorId?: string | null) {
	return {
		type: CONSTANTS.SET_SAVED_VENDOR_ID,
		payload: savedVendorId,
	};
}
