import { getWedding } from '@utils/getWedding';
import { CookieStorage } from 'cookie-storage';
import { useCallback, useState } from 'react';
import { XO_SESSION_TOKEN } from '../../../../../../../../constants/membership';

interface GetWeddingResponse {
	wedding: {
		id: string;
	};
}

export const useSetup = (
	membership: Redux.Membership,
	setPendingGetWedding: React.Dispatch<React.SetStateAction<boolean>>,
	getFavorite: (weddingId?: string, sessionToken?: string) => void,
	setWeddingId: (weddingId?: string) => void,
	emptyFavoriteHeart: () => void,
) => {
	const [previousMember, setPreviousMember] =
		useState<Membership.Member | null>(null);

	return useCallback(() => {
		const { member } = membership;

		const cookieStorage = new CookieStorage();
		const sessionToken = cookieStorage.getItem(XO_SESSION_TOKEN) || undefined;

		if (previousMember === member) {
			return;
		}

		if (member) {
			setPreviousMember(member);
			setPendingGetWedding(true);
			getWedding(member.id, sessionToken).then(
				(response: GetWeddingResponse) => {
					setWeddingId(response.wedding.id);
					getFavorite(response.wedding.id, sessionToken);
					setPendingGetWedding(false);
				},
			);
		} else {
			emptyFavoriteHeart();
		}
	}, [
		membership,
		setPendingGetWedding,
		getFavorite,
		setWeddingId,
		emptyFavoriteHeart,
		previousMember,
		setPreviousMember,
	]);
};
