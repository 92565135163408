import WeddingClient from '@xo-union/sdk-weddings';
import { weddingsApiKey, weddingsRootUrl } from '../../settings';

export const getWedding = (memberId, sessionToken) => {
	const weddingClient = new WeddingClient({
		host: weddingsRootUrl,
		apiKey: weddingsApiKey,
		membershipSessionToken: sessionToken,
	});
	return weddingClient.getWeddingByMemberId(memberId);
};

export default getWedding;
