const createAddressObject = (
	address1,
	address2,
	city,
	state,
	postalCode,
	longitude,
	latitude,
	isPublic,
) => {
	// instantiate address object with required keys/values
	const address = { address1, city, state, postalCode };

	// include optional keys only if their values are *not* null
	if (address2) address.address2 = address2;
	if (isPublic) address.isPublic = isPublic;
	// technically coordinates can be 0
	if (longitude || longitude === 0) address.longitude = longitude;
	if (latitude || latitude === 0) address.latitude = latitude;

	return address;
};

const createContactInfoObject = (name, email, phoneNumber) => {
	const contactInfo = {};

	// include optional keys only if their values are *not* null
	if (name) contactInfo.name = name;
	if (email) contactInfo.email = email;
	if (phoneNumber) contactInfo.phone_number = phoneNumber;

	// only return an object if vendor has any values for name or contact info
	return Object.keys(contactInfo).length ? contactInfo : null;
};

export const buildAddress = (vendorAddress) => {
	// there are times when 'address' properties are null,
	// but Saved API does not accept null values for these props
	const address1 = vendorAddress.address1 || '';
	const city = vendorAddress.city || '';
	const state = vendorAddress.state || '';
	const postalCode = vendorAddress.postalCode || '';

	// Saved API accepts these optional values *unless* they are null
	const { address2 } = vendorAddress;
	const { isPublic } = vendorAddress;
	const { longitude } = vendorAddress;
	const { latitude } = vendorAddress;

	return createAddressObject(
		address1,
		address2,
		city,
		state,
		postalCode,
		longitude,
		latitude,
		isPublic,
	);
};

export const formatSavedVendorData = (vendor) => {
	// storefront vendor objects do not always have vendor.location, vendors_search does
	const vendorAddress =
		(vendor.location && vendor.location.address) || vendor.address;

	// storefront stores have a categoryCode prop, vendors_search does not
	const categoryCode = vendor.categoryCode || vendor.categories[0].code;

	// there are times when bio/contact_info properties are null,
	// but Saved API does not accept null values for these props
	const name = vendor.bio && vendor.bio.name;
	const email = vendor.emails && vendor.emails[0].address;
	const phoneNumber = vendor.phones && vendor.phones[0].number;
	const contactInfo = createContactInfoObject(name, email, phoneNumber);

	const address = buildAddress(vendorAddress);

	const savedVendorObj = {
		saved_vendor: {
			address: { ...address },
			category_code: categoryCode,
			local_vendor: {
				id: vendor.id,
				name: vendor.name,
			},
		},
	};

	// add contactInfo only if vendor has name/email/phone
	if (contactInfo !== null)
		savedVendorObj.saved_vendor.contact_info = contactInfo;

	return savedVendorObj;
};

export default formatSavedVendorData;
