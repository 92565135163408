type PlaceholderImageConstants = Omit<Vendor.PhotoMedia, 'mediaType'>;

export const PLACEHOLDER_CONSTANTS: PlaceholderImageConstants = {
	id: 'gallery-placeholder-54de075e-7a99-4211-83a2-635901ab56e4',
	url: 'https://media-api.xogrp.com/images/54de075e-7a99-4211-83a2-635901ab56e4',
	height: '500',
	width: '500',
};

export const QUALITY_PROP = '90';

export const NO_IMAGE_PATH =
	'https://media-api.xogrp.com/images/95faea13-b9ce-4d1f-8bc1-1debbfac97b9';
