import type { ReviewMedia } from '@typings/vendor/properties';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import { Body1 } from '@xo-union/tk-ui-typography';
import classname from 'classnames';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useReviewDate } from '../../../../pages/Storefront/containers/reviews/components/review/hooks/useReviewDate';
import ReviewStars from '../../../../pages/Storefront/containers/vendor_info/components/review_stars';
import { currentReviewAtom } from '../../atoms';
import Styles from './ReviewDetails.scss';
interface ReviewDetailsContent {
	currentReview: ReviewMedia;
}
const ReviewDetailsContent = ({ currentReview }: ReviewDetailsContent) => {
	const [formattedCreatedDate] = useReviewDate(
		currentReview?.reviewCreatedDate || '',
		'M/dd/yy',
	);

	return (
		<>
			<div className={Styles.reviewMetadata}>
				<Body1 bold>
					{currentReview?.reviewAuthorFirstname}&nbsp;
					{currentReview?.reviewAuthorLastname.slice(0, 1)}&nbsp;·&nbsp;
				</Body1>
				<Body1>Reviewed on {formattedCreatedDate}</Body1>
			</div>
			<div className={Styles.reviewRating}>
				<ReviewStars
					overallRating={currentReview?.reviewRating || 0}
					size="md"
				/>
				<Body1 bold>{currentReview?.reviewRating.toPrecision(2)}</Body1>
			</div>
			<Body1>{currentReview?.reviewContent}</Body1>
		</>
	);
};

const ReviewDetails = () => {
	const currentReview = useAtomValue(currentReviewAtom);
	const partial = classname(Styles.reviewDetailSkeleton, Styles.partial);

	return (
		<div className={Styles.reviewDetail}>
			{currentReview?.reviewRating ? (
				<ReviewDetailsContent currentReview={currentReview} />
			) : (
				<Skeleton>
					<Bone className={Styles.reviewDetailSkeleton} />
					<Bone className={partial} />
					<Bone className={Styles.reviewDetailSkeleton} />
				</Skeleton>
			)}
		</div>
	);
};

export default ReviewDetails;
