import { useAppSelector } from '@redux/hooks';
import { Link } from '@xo-union/tk-component-buttons';
import Icon from '@xo-union/tk-component-icons';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './EmptyState.scss';

export const BaseEmptyState = () => {
	const breadCrumbUrl = useAppSelector(
		(state) => state.vendor.vendor?.breadcrumbInfo.cityUrl,
	);

	return (
		<div className={Styles.bodyEmptyState}>
			<Icon name="camera_2" size="lg" className={Styles.emptyStateIcon} />
			<div className={Styles.bodyEmptyStateText}>
				<Editorial bold>You've seen it all!</Editorial>
				<Body1>Still looking for the perfect match?</Body1>
			</div>
			<Link color="secondary" size="md" href={breadCrumbUrl}>
				Continue browsing vendors
			</Link>
		</div>
	);
};

export const EmptyState = () => {
	return (
		<div className={Styles.emptyStateCentering}>
			<div className={Styles.emptyState}>
				<BaseEmptyState />
			</div>
		</div>
	);
};
