import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './compact-tour-cta.scss';

export const CompactTourCta = () => {
	return (
		<div className={Styles.container}>
			<Icon name="virtual_tour" data-testid="360-tour" size="md" />
			<Body1 bold>Virtual Tour</Body1>
		</div>
	);
};
