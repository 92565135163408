import React from 'react';
import type { FCWithChildren } from 'types/react-extended';
import Styles from './styles.scss';
import type { CommonImageProps } from './types';

interface PlaceholderImageProps extends CommonImageProps {
	imageStatus: CustomHooks.UseImageStatus;
}

const PlaceholderImage: FCWithChildren<PlaceholderImageProps> = (props) => {
	const { imageStatus, imageClass, children } = props;

	if (imageStatus !== 'ERRORED') {
		return null;
	}

	return (
		<div className={imageClass || Styles.placeholderImage} role="presentation">
			{children}
		</div>
	);
};

export default PlaceholderImage;
