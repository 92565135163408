import React, { type SyntheticEvent, type FC } from 'react';
import ImageWrapper from './ImageWrapper';
import type { CommonImageProps } from './types';

export interface ThumbnailImageProps extends CommonImageProps {
	imageClass?: string;
	imageStatus: CustomHooks.UseImageStatus;
	onError: (imageReference: SyntheticEvent<HTMLImageElement>) => void;
	onLoad: () => void;
	mediaItem: Vendor.Media;
	preload?: boolean;
	height?: number;
	width?: number;
	lazy?: boolean;
}

const ThumbnailImage: FC<ThumbnailImageProps> = (props) => (
	<ImageWrapper {...props} />
);

export default ThumbnailImage;
