import { SyntheticEvent, useCallback, useMemo, useState } from 'react';

type UseImage = [
	CustomHooks.UseImageStatus,
	() => void,
	(imageReference: SyntheticEvent<HTMLImageElement>) => void,
];

const useImage = (imageFallbackUrl: string): UseImage => {
	const [isImageErrored, setIsImageErrored] = useState(false);
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const onLoad = useCallback(() => {
		setIsImageLoaded(true);
	}, []);

	const onError = useCallback(
		(imageReference: SyntheticEvent<HTMLImageElement>) => {
			const local = imageReference;
			local.currentTarget.src = imageFallbackUrl;
			setIsImageErrored(true);
		},
		[],
	);

	const imageStatus: CustomHooks.UseImageStatus = useMemo(() => {
		if (isImageLoaded && !isImageErrored) {
			return 'SUCCESS';
		}
		if (isImageLoaded && isImageErrored) {
			return 'ERRORED';
		}

		return 'INIT';
	}, [isImageLoaded, isImageErrored]);

	return [imageStatus, onLoad, onError];
};

export default useImage;
