import debounce from '@utils/debounce';
import { useEffect, useState } from 'react';

type UseScreenDetailsReturnValues = [
	isLandscape: boolean,
	width: number,
	height: number,
];

const useScreenDetails = (): UseScreenDetailsReturnValues => {
	const [isLandscape, setIsLandscape] = useState(
		() => window.innerWidth > window.innerHeight,
	);
	const [width, setWidth] = useState(() => window.innerWidth);
	const [height, setHeight] = useState(() => window.innerHeight);

	useEffect(() => {
		let isMounted = true;
		const resizeHandler = () => {
			if (isMounted) {
				setWidth(window.innerWidth);
				setHeight(window.innerHeight);
				setIsLandscape(window.innerWidth > window.innerHeight);
			}
		};

		const debouncedResizeHandler = debounce(resizeHandler, 250, false);

		window.addEventListener('resize', debouncedResizeHandler);

		return () => {
			window.removeEventListener('resize', debouncedResizeHandler);
			isMounted = false;
		};
	}, []);

	return [isLandscape, width, height];
};

export { useScreenDetails };
