import MembershipModalRedux, {
	OpenModalType,
} from '@xo-union/tk-component-membership-modal-redux';
import React, { useEffect, useState, FC } from 'react';

interface MembershipModalProps {
	openModal: OpenModalType | null;
	setOpenModal: React.Dispatch<React.SetStateAction<string | null>>;
	pendingGetWedding: boolean;
	createFavorite: (weddingId: string | undefined) => void;
	weddingId: string | undefined;
}

const MembershipModal: FC<MembershipModalProps> = ({
	openModal,
	setOpenModal,
	pendingGetWedding,
	createFavorite,
	weddingId,
}) => {
	const [pendingSave, setPendingSave] = useState(false);

	const closeModal = (loggedIn = false) => {
		setOpenModal(null);
		if (loggedIn) {
			setPendingSave(loggedIn);
		}
	};

	useEffect(() => {
		if (!pendingGetWedding && pendingSave) {
			createFavorite(weddingId);
			setPendingSave(false);
		}
	}, [pendingGetWedding, pendingSave, weddingId]);

	if (!openModal) {
		return null;
	}

	return (
		<MembershipModalRedux
			metadata={{
				userAction: 'favorites button',
				applicationName: 'marketplace',
			}}
			openModalType={openModal}
			onClickLogIn={() => setOpenModal('LOG_IN')}
			onClickSignUp={() => setOpenModal('SIGN_UP')}
			onSignUpSuccess={() => closeModal(true)}
			onLogInSuccess={() => closeModal(true)}
			onClose={() => closeModal(false)}
		/>
	);
};

export default MembershipModal;
