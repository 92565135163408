import useImage from '@hooks/useImage';
import cx from 'classnames';
import React, { Fragment, FC } from 'react';

import { CompactTourCta } from '@components/tour/compact-tour-cta';
import { isUnifiedLightboxOpenAtom } from '@components/unified-lightbox/atoms';
import { useAtom } from 'jotai';
import ThumbnailImage from './ThumbnailImage';
import TourCTA from './TourCTA';
import { PLACEHOLDER_CONSTANTS } from './constants';
import Playable, { PlayableRenderProps } from './playable';
import Styles from './styles.scss';
import type { CommonImageProps } from './types';

export interface TourProps extends CommonImageProps {
	canPlay: boolean;
	mediaItem: Vendor.TourMedia;
	/* eslint-disable-next-line react/no-unused-prop-types */
	preload?: boolean;
	/* eslint-disable-next-line react/no-unused-prop-types */
	height?: number;
	/* eslint-disable-next-line react/no-unused-prop-types */
	width?: number;
	/* eslint-disable-next-line react/no-unused-prop-types */
	lazy?: boolean;
	compact?: boolean;
}

const CTA = ({ compact }: { compact?: boolean }) => {
	if (compact) {
		return <CompactTourCta />;
	}

	return <TourCTA />;
};

const CTAThumbnail = ({ mediaItem, compact, ...rest }: TourProps) => {
	const [imageStatus, onLoad, onError] = useImage(PLACEHOLDER_CONSTANTS.url);

	const [isUnifiedLightboxOpen] = useAtom(isUnifiedLightboxOpenAtom);

	const isOnLightbox = isUnifiedLightboxOpen;

	return (
		<Fragment>
			{imageStatus === 'SUCCESS' && !isOnLightbox && <CTA compact={compact} />}
			<ThumbnailImage
				{...rest}
				imageStatus={imageStatus}
				onError={onError}
				onLoad={onLoad}
				imageClass={Styles.thumbnailTour}
				mediaItem={mediaItem}
			/>
		</Fragment>
	);
};

export const Tour: FC<TourProps> = (props) => {
	const { canPlay, mediaItem } = props;

	if (canPlay) {
		return (
			<Playable
				media={mediaItem}
				render={({ playMedia, isPlaying }: PlayableRenderProps) => {
					const tourContainerClasses = cx(
						Styles.videoContainer,
						Styles.canPlay,
						isPlaying ? '' : Styles.notPlayingTour,
					);
					return (
						<div
							title="tourWrapper"
							role="presentation"
							className={tourContainerClasses}
							onClick={playMedia}
							onKeyDown={playMedia}
						>
							{!isPlaying && <CTAThumbnail {...props} />}
							<iframe
								src={`${mediaItem.url}&play=${isPlaying ? '1' : '0'}`}
								title={`tour-${mediaItem.id}`}
							/>
						</div>
					);
				}}
			/>
		);
	}

	return (
		<div title="tourWrapper" className={Styles.videoContainer}>
			<CTAThumbnail {...props} />
		</div>
	);
};

export default Tour;
