import classNames from 'classnames';
import React, { memo } from 'react';
import type { PhotoMedia, Media as VendorMedia } from 'types/vendor';
import MediaComponent from '../../../../pages/Storefront/components/Media';
import Styles from './MediaContainer.scss';
import ReviewImageIcon from './components/ReviewImageIcon/ReviewImageIcon';

export interface MediaProps {
	media: VendorMedia;
}

export const landscape = { width: 1016, height: 678 } as const;
export const portrait = { width: 513, height: 770 } as const;

const getDimensionProps = (media: VendorMedia) => {
	if (
		media.mediaType === 'VIDEO' ||
		media.mediaType === '360TOUR' ||
		media.mediaType === 'VIDEO_UPLOAD'
	) {
		return landscape;
	}
	const photoMedia = media as PhotoMedia;

	if (!photoMedia.height || !photoMedia.width) {
		return landscape;
	}

	const height = Number.parseInt(photoMedia.height, 10);
	const width = Number.parseInt(photoMedia.width, 10);

	return width >= height ? landscape : portrait;
};

export const MediaContainer = memo(({ media }: MediaProps) => {
	const dimensionProps = getDimensionProps(media);
	const hasDimensions = 'height' in media && 'width' in media;

	return (
		<>
			<div
				className={classNames(Styles.mediaContainerWrapper, 'bone', {
					[Styles.mediaUnknownDimensions]: !hasDimensions,
				})}
				data-testid={`media-container-wrapper-${media.id}`}
			>
				<div className={Styles.reviewIconContainer}>
					<ReviewImageIcon media={media} />
				</div>
				<div className={Styles.mediaContainer}>
					<MediaComponent
						canPlay
						smartCrop
						mediaItem={media}
						{...dimensionProps}
					/>
				</div>
			</div>
		</>
	);
});
