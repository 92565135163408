import Styles from '../PlaceholderIcon.scss';

const getVideoPlaceholderIconStyles = (
	isLightboxCurrentViewDetail: boolean,
) => {
	if (isLightboxCurrentViewDetail) {
		return Styles.lightboxDetailVideoPlaceholderIcon;
	}

	return Styles.placeholderIcon;
};

const getTourPlaceholderIconStyles = (
	isUnifiedLightboxOpen: boolean,
	isLightboxCurrentViewDetail: boolean,
) => {
	if (!isUnifiedLightboxOpen) {
		return Styles.storefrontTourPlaceholderIcon;
	}

	if (isLightboxCurrentViewDetail) {
		return Styles.lightboxDetailTourPlaceholderIcon;
	}

	return Styles.placeholderIcon;
};
export { getVideoPlaceholderIconStyles, getTourPlaceholderIconStyles };
