const isMediaOfType = <T extends Vendor.Media>(
	mediaItem: Vendor.Media,
	type: keyof Record<T['mediaType'], string>,
): mediaItem is T => {
	return mediaItem.mediaType === type;
};

const isPhotoWide = (media: Vendor.PhotoMedia) => {
	return Number(media.width) > Number(media.height);
};

export { isMediaOfType, isPhotoWide };
