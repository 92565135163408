import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import Icon from '@xo-union/tk-component-icons';
import classNames from 'classnames';
import React from 'react';
import Styles from './TourIcon.scss';

const TourIcon = () => {
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	return (
		<div className={classNames(Styles.tourIconContainer, Styles.iconContainer)}>
			<Icon
				className={Styles.tourIcon}
				color="white"
				size={isMobile ? 'md' : 'lg'}
				name="virtual_tour"
			/>
		</div>
	);
};

export default TourIcon;
