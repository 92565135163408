import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';

export const useReviewDate = (
	createdDate: string,
	dateFormat = 'M/dd/yyyy',
) => {
	return useMemo(() => {
		if (!createdDate) {
			return ['', ''];
		}
		const parsedDate = parseISO(createdDate);
		const formattedDate = format(parsedDate, dateFormat);
		const date = parsedDate.toISOString();

		return [formattedDate, date];
	}, [createdDate, dateFormat]);
};
