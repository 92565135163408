import type { Gallery, Review } from '@typings/reviews';
import type { MediaType } from '@typings/vendor/properties';
import type { VendorMediaKey } from './atoms';

export const getFilterByMediaType = (
	mediaType: (MediaType | 'REVIEW') | undefined,
): VendorMediaKey => {
	switch (mediaType) {
		case 'PHOTO':
			return 'photos';
		case 'VIDEO':
		case 'VIDEO_UPLOAD':
			return 'videos';
		case '360TOUR':
			return 'tours';
		case 'REVIEW':
			return 'reviews';
		default:
			return 'all';
	}
};

export const mapReviewsMedia = (
	media: Gallery[],
	review: Review | null,
	mediaApiUrl: string,
) => {
	return media.map((media: Gallery) => ({
		...media,
		id: media.sourceId,
		mediaType: media.typeCode,
		width: media.width?.toString() || '0',
		height: media.height?.toString() || '0',
		url: mediaApiUrl + media.sourceId,
		reviewId: review?.id || '',
		reviewRating: review?.rating || 0,
		reviewCreatedDate: review?.createdDate || media.timestamp.createdAt,
		reviewAuthorFirstname: review?.reviewer.firstName || '',
		reviewAuthorLastname: review?.reviewer.lastName || '',
		reviewContent: review?.content || '',
	}));
};
