import { Button } from '@xo-union/tk-component-buttons';
import Icon from '@xo-union/tk-component-icons';
import classnames from 'classnames';
import React, { type FC } from 'react';
import Styles from '../../styles.scss';

interface Props {
	filled: boolean;
	onToggleHandler: () => void;
}

const ExpandedHeart: FC<Props> = ({ filled, onToggleHandler }) => (
	<Button
		color="tertiary"
		id="favorites-button-expanded"
		size="md"
		className={Styles.favButtonExpanded}
		onClick={onToggleHandler}
	>
		<div>
			<Icon
				name={filled ? 'heart_filled' : 'heart'}
				size="md"
				aria-hidden="true"
				className={classnames(Styles.expandedHeart, {
					[Styles.expandedHeartFilled]: filled,
				})}
			/>
			{filled ? 'Saved Vendor' : 'Save Vendor'}
		</div>
	</Button>
);

export default ExpandedHeart;
