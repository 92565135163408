import { weddingsApiKey, weddingsRootUrl } from '../settings';

const get = (opts) => {
	const { weddingId, sessionToken } = opts;
	// global fetch
	return fetch(
		`${weddingsRootUrl}/weddings/${weddingId}/saved_vendors?api_key=${weddingsApiKey}`,
		{
			method: 'GET',
			headers: {
				'MEMBERSHIP-SESSION-TOKEN': sessionToken,
			},
		},
	).then((res) => res.json());
};

const create = (opts) => {
	const { weddingId, data, sessionToken } = opts;
	return fetch(
		`${weddingsRootUrl}/weddings/${weddingId}/saved_vendors?api_key=${weddingsApiKey}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'MEMBERSHIP-SESSION-TOKEN': sessionToken,
			},
			body: JSON.stringify(data),
		},
	).then((res) => res.json());
};

const destroyByObjectId = (opts) => {
	const { weddingId, savedVendorId, sessionToken } = opts;
	return fetch(
		`${weddingsRootUrl}/weddings/${weddingId}/saved_vendors?api_key=${weddingsApiKey}`,
		{
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'MEMBERSHIP-SESSION-TOKEN': sessionToken,
			},
			body: JSON.stringify({ saved_vendor_ids: [savedVendorId] }),
		},
	);
};

export default {
	get,
	create,
	destroyByObjectId,
};
