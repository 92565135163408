import savedVendors from '@utils/savedVendors';
import { useCallback } from 'react';

interface Favorite {
	['local_vendor']: { id: string };
	id?: string | null;
}

export const useGetFavorite = (
	vendor: Vendor.Decorated,
	setSavedVendorId: (savedVendorId?: string | null) => void,
	fillFavoriteHeart: () => void,
) => {
	return useCallback(
		(weddingId?: string, sessionToken?: string) => {
			return savedVendors
				.get({
					sessionToken,
					weddingId,
				})
				.then((response) => {
					response.saved_vendors.forEach((favorite: Favorite) => {
						if (
							(favorite.local_vendor && favorite.local_vendor.id) === vendor.id
						) {
							setSavedVendorId(favorite.id);
							fillFavoriteHeart();
						}
					});
				});
		},
		[vendor, fillFavoriteHeart, setSavedVendorId],
	);
};
